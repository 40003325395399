import { ButtonGroup } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { TextareaAutosize } from '@mui/material';
import { format } from 'date-fns';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import { FhirClientContext } from '../../FhirClientContext';
import {
  getExternalServerUrl,
  getExternalServiceRequestId,
  isExternalReference,
} from '../utils/checkExternalReference';
import { getVectorConfig } from '../utils/getConfig';
import { getOwnDisplayname, getOwnOrganization, getOwnPractitionerRole } from '../utils/getOwn';
import DicomUploadDialog from './DicomUploadDialog';
import PdfExport from './pdfexport/PdfExport';

class CaseFooter extends React.Component {
  static contextType = FhirClientContext;

  constructor(props) {
    super(props);

    this.fileType = [
      'Anamnesis',
      'MedicalLetter',
      'Haemodynamics',
      'Respiration',
      'BloodGasAnalysis',
      'Perfusors',
      'InfectiousDisease',
      'Radiology',
      'Medication',
      'Lab',
      'Others',
    ];

    this.state = {
      dialogRequestOpen: false,
      dialogAttachmentOpen: false,
      message: '',
      selectedFile: null,
      fileCategory: this.fileType[10],
      fileError: '',
      fileUploadAllowed: false,
      ownOrganization: '',
      ownPractitionerRole: '',
      confirmationTitle: '',
      confirmationText: '',
      confirmationOpen: false,
      currentDraft: '',
      videoconferencesEnabled: false,
      dicomEnabled: false,
      dialogDicomOpen: false,
      dicomError: '',
      dicomUploadAllowed: false,
      selectedDicomFile: null,
    };

    this.saveDicom = this.saveDicom.bind(this);
  }

  componentWillUnmount() {}

  async componentDidMount() {
    const config = await getVectorConfig('/config');
    this.setState({ videoconferencesEnabled: config.enable_videoconferences });
    this.setState({ dicomEnabled: config.enable_dicom });

    // check for own organization
    const client = this.context.client;
    this.props.setOwnDisplayname(await getOwnDisplayname(client));
    this.setState({
      ownOrganization: await getOwnOrganization(client),
      ownPractitionerRole: await getOwnPractitionerRole(client),
    });
  }

  // TODO warum muss ich das hier binden und in anderen Klassen nicht??
  onRequestButtonClick = () => {
    const client = this.context.client;
    client.request(`ServiceRequest/${this.props.sr_id}`, { pageLimit: 0, flat: true }).then(srData => {
      client
        .request(
          {
            url: `Communication?based-on=ServiceRequest/${this.props.sr_id}&_sort=-sent`,
            federatedServerUrl: getExternalServerUrl(srData, client),
          },
          {
            pageLimit: 0,
            flat: true,
          }
        )
        .then(communicationData => {
          if (communicationData[0] && communicationData[0].status == 'preparation') {
            this.setState({
              currentDraft: communicationData[0].id,
              message: communicationData[0].note ? communicationData[0].note[0].text : '',
            });
          }
          this.setState({ dialogRequestOpen: true });
        });
    });
  };

  onRequestDialogClose = () => {
    this.setState({ dialogRequestOpen: false, message: '' });
  };

  onTextfieldChange = event => {
    this.setState({ message: event.target.value });
  };

  onSaveRequestAsDraft = () => {
    const client = this.context.client;
    if (this.state.currentDraft) {
      client.request(`ServiceRequest/${this.props.sr_id}`, { pageLimit: 0, flat: true }).then(srData => {
        client
          .patch(
            `Communication/${this.state.currentDraft}`,
            [
              {
                op: 'add',
                path: '/note',
                value: [
                  {
                    text: `${this.state.message}`,
                  },
                ],
              },
              {
                op: 'replace',
                path: '/sent',
                value: format(new Date(), "yyyy-MM-dd'T'HH:mm:ss.SSSSXXX"),
              },
            ],
            {
              url: `Communication/${this.state.currentDraft}`,
              federatedServerUrl: getExternalServerUrl(srData, client),
            }
          )
          .then(patchedData => {
            this.props.updateCommunications(this.state.currentDraft);
          });
      });
    } else {
      this.createCommunication(true, '', '');
    }
    this.setState({ dialogRequestOpen: false });
  };

  onSendRequest = () => {
    const client = this.context.client;
    if (this.state.currentDraft) {
      client.request(`ServiceRequest/${this.props.sr_id}`, { pageLimit: 0, flat: true }).then(srData => {
        const externalServerUrl = getExternalServerUrl(srData, client);
        const senderRecipientData = this.getSenderRecipient(srData, externalServerUrl);

        client
          .patch(
            `Communication/${this.state.currentDraft}`,
            [
              {
                op: 'add',
                path: '/note',
                value: [
                  {
                    text: `${this.state.message}`,
                  },
                ],
              },
              {
                op: 'add',
                path: '/recipient',
                value: [
                  {
                    reference: `${senderRecipientData.recipient}`,
                  },
                ],
              },
              {
                op: 'replace',
                path: '/sent',
                value: format(new Date(), "yyyy-MM-dd'T'HH:mm:ss.SSSSXXX"),
              },
              {
                op: 'replace',
                path: '/status',
                value: 'in-progress',
              },
            ],
            {
              url: `Communication/${this.state.currentDraft}`,
              federatedServerUrl: getExternalServerUrl(srData, client),
            }
          )
          .then(patchedData => {
            this.props.updateCommunications(this.state.currentDraft);
            this.setState({ currentDraft: '' });
          });
      });
    } else {
      this.createCommunication(false, '', '');
    }
    this.setState({ dialogRequestOpen: false });
  };

  onAttachmentButtonClick = () => {
    this.setState({ dialogAttachmentOpen: true });
  };

  onAttachmentDialogClose = () => {
    this.setState({ fileError: '', dialogAttachmentOpen: false });
  };

  onDicomButtonClick = () => {
    this.setState({ dialogDicomOpen: true });
  };

  onDicomDialogClose = () => {
    this.setState({ dicomError: '', dialogDicomOpen: false });
  };

  onSaveAttachment = () => {
    this.saveAttachment();
    this.setState({ dialogAttachmentOpen: false });
  };

  onFileCategoryChange = event => {
    this.setState({ fileCategory: event.target.value });
  };

  onFileChangeHandler = event => {
    const { t, i18n } = this.props;
    if (!event.target.files[0]) return;
    this.setState({ selectedFile: event.target.files[0] });

    // check file type
    let validFileType = false;
    switch (event.target.files[0].type) {
      case 'image/jpeg':
      case 'image/png':
        validFileType = true;
    }

    if (!validFileType) {
      // show error, disable save button
      this.setState({ fileError: t('Invalid file type'), fileUploadAllowed: false });
      return;
    }

    // check file size, 10MB is maximum
    if (event.target.files[0].size > 10 * 1024 * 1024) {
      // show error, disable save button
      this.setState({ fileError: t('File is too large'), fileUploadAllowed: false });
      return;
    }

    this.setState({ fileError: '', fileUploadAllowed: true });
  };

  onCloseConsultationButtonClick = () => {
    const { t } = this.props;
    if (!this.props.srIsOnHoldBy) {
      // send request to close the case
      this.setState({
        confirmationTitle: t('Send request for closing the consultation?'),
        confirmationText: t('The consultation partner has to confirm the request to close the consultation.'),
        confirmationAgree: t('Send'),
        confirmationDisagree: t('Cancel'),
        confirmationOpen: true,
      });
    } else {
      // close the case
      this.setState({
        confirmationTitle: t('Request for closing the consultation'),
        confirmationText: t('The consultation partner wants to close the consultation.'),
        confirmationAgree: t('Confirm'),
        confirmationDisagree: t('Decline'),
        confirmationOpen: true,
      });
    }
  };

  onStartVideoconferenceButtonClick = async () => {
    const client = this.context.client;
    let roomName = uuidv4(); // ⇨ '9b1deb4d-3b7d-4bad-9bdd-2b0d7b3dcb6d'
    this.createCommunication(false, roomName, '');
    await client.refreshIfNeeded();
    this.props.setVideoconferenceLink(roomName);
  };

  onConfirmationAgree = () => {
    if (!this.props.srIsOnHoldBy) {
      // send request to close the case
      // read sr
      const client = this.context.client;
      client
        .request('ServiceRequest/' + this.props.sr_id, {
          pageLimit: 0,
          flat: true,
        })
        .then(sr => {
          // set new status for ServiceRequest
          if (getExternalServiceRequestId(sr, client)) {
            client
              .request({
                url: 'ServiceRequest/' + getExternalServiceRequestId(sr, client),
                federatedServerUrl: getExternalServerUrl(sr, client),
              })
              .then(srData => {
                srData.status = 'on-hold';
                if (this.state.ownPractitionerRole) {
                  srData.supportingInfo = [{ reference: this.state.ownPractitionerRole }];
                } else {
                  srData.supportingInfo = [{ reference: this.state.ownOrganization }];
                }
                this.props.setSrIsOnHoldBy(this.state.ownOrganization);
                client.update(srData, {
                  url: getExternalServerUrl(sr, client),
                  federatedServerUrl: getExternalServerUrl(sr, client),
                });
              });
          }
          sr.status = 'on-hold';
          if (this.state.ownPractitionerRole) {
            sr.supportingInfo = [{ reference: this.state.ownPractitionerRole }];
          } else {
            sr.supportingInfo = [{ reference: this.state.ownOrganization }];
          }
          this.props.setSrIsOnHoldBy(this.state.ownOrganization);
          client.update(sr);
        });
    } else {
      // close the case
      const client = this.context.client;
      client
        .request('ServiceRequest/' + this.props.sr_id, {
          pageLimit: 0,
          flat: true,
        })
        .then(sr => {
          // set new status for ServiceRequest
          if (getExternalServiceRequestId(sr, client)) {
            client
              .request({
                url: 'ServiceRequest/' + getExternalServiceRequestId(sr, client),
                federatedServerUrl: getExternalServerUrl(sr, client),
              })
              .then(srData => {
                srData.status = 'completed';
                this.props.setSrIsArchived(true);
                client.update(srData, {
                  url: getExternalServerUrl(sr, client),
                  federatedServerUrl: getExternalServerUrl(sr, client),
                });
              });
          }
          sr.status = 'completed';
          this.props.setSrIsArchived(true);
          client.update(sr);
        });
    }
    this.setState({ confirmationOpen: false });
  };

  onConfirmationDisagree = () => {
    if (this.props.srIsOnHoldBy) {
      // decline request to close the case
      // read sr
      const client = this.context.client;
      client
        .request('ServiceRequest/' + this.props.sr_id, {
          pageLimit: 0,
          flat: true,
        })
        .then(sr => {
          // set new status for ServiceRequest
          if (getExternalServiceRequestId(sr, client)) {
            client
              .request({
                url: 'ServiceRequest/' + getExternalServiceRequestId(sr, client),
                federatedServerUrl: getExternalServerUrl(sr, client),
              })
              .then(srData => {
                srData.status = 'active';
                client.update(srData, {
                  url: getExternalServerUrl(sr, client),
                  federatedServerUrl: getExternalServerUrl(sr, client),
                });
              });
          }

          sr.status = 'active';
          client.update(sr);
          this.props.setSrIsOnHoldBy('');
        });
    }
    this.setState({ confirmationOpen: false });
  };

  onConfirmationDialogClose = () => {
    this.setState({ confirmationOpen: false });
  };

  getSenderRecipient = (srData, externalServerUrl) => {
    const client = this.context.client;
    const senderRole = this.state.ownPractitionerRole ? this.state.ownPractitionerRole : this.state.ownOrganization;
    let isExternalPerformer = isExternalReference(srData.performer[0].reference, client);

    let sender = null;
    let recipient = null;
    let extension = null;

    if (externalServerUrl == null && isExternalPerformer) {
      // federated consultation but i am the base server
      const externalBaseUrl = new URL(srData.performer[0].reference).origin + '/hapi-fhir-jpaserver/fhir/';
      sender = senderRole;
      recipient = externalBaseUrl + 'Organization/' + this.props.srRecipient;
    } else if (externalServerUrl) {
      // federated consultation but i am the federated server
      const clientServerUrl = client.state.serverUrl.endsWith('/')
        ? client.state.serverUrl
        : client.state.serverUrl + '/';
      sender = clientServerUrl + this.state.ownOrganization;
      recipient = 'Organization/' + this.props.srRecipient;
      extension = [
        {
          url: 'clinic.amp.app.sender.person',
          valueReference: clientServerUrl + senderRole,
        },
      ];
    } else {
      // internal consultation
      sender = senderRole;
      recipient = 'Organization/' + this.props.srRecipient;
    }

    return { sender: sender, recipient: recipient, extension: extension };
  };

  createCommunication(draft, videoconfName, attachment) {
    const client = this.context.client;
    client.request(`ServiceRequest/${this.props.sr_id}`, { pageLimit: 0, flat: true }).then(srData => {
      const status = draft ? 'preparation' : 'in-progress';
      const srId = getExternalServiceRequestId(srData, client) ?? this.props.sr_id;

      const externalServerUrl = getExternalServerUrl(srData, client);
      const senderRecipientData = this.getSenderRecipient(srData, externalServerUrl);

      let resource = {
        resourceType: 'Communication',
        basedOn: [
          {
            reference: 'ServiceRequest/' + srId,
          },
        ],
        status: status,
        category: [
          {
            text: this.props.srCategory,
          },
        ],
        note: [
          {
            text: this.state.message,
          },
        ],
        priority: 'asap',
        subject: {
          reference: 'Patient/' + this.props.srSubject,
        },
        sent: format(new Date(), "yyyy-MM-dd'T'HH:mm:ss.SSSSXXX"),
        recipient: [
          {
            reference: draft ? '' : senderRecipientData.recipient,
          },
        ],
        sender: {
          reference: senderRecipientData.sender,
        },
      };

      if (senderRecipientData.extension !== null) {
        resource.extension = senderRecipientData.extension;
      }

      // communication represents a message
      if (this.state.message != '') {
        resource = {
          ...resource,
          note: [
            {
              text: this.state.message,
            },
          ],
        };
      }

      // communication represents a videoconference
      if (videoconfName) {
        resource = {
          ...resource,
          medium: [
            {
              coding: [
                {
                  code: 'VIDEOCONF',
                  userSelected: true,
                },
              ],
            },
          ],
          topic: {
            coding: [
              {
                code: 'VIDEOCONFERENCE',
              },
            ],
          },
          payload: [
            {
              contentString: videoconfName,
            },
            {
              contentString: JSON.stringify({ start_time: format(new Date(), "yyyy-MM-dd'T'HH:mm:ss.SSSSXXX") }),
            },
          ],
        };
      }

      if (attachment) {
        resource = {
          ...resource,
          payload: [
            {
              contentReference: {
                reference: attachment,
              },
            },
          ],
        };
      }

      client
        .create(resource, {
          url: externalServerUrl,
          federatedServerUrl: externalServerUrl,
          headers: { Authorization: 'Bearer ' + client.state.tokenResponse.access_token },
        })
        .then(data => {
          this.props.addCommunication(data.id, true);
          if (attachment !== '') {
            const event = new CustomEvent('newAttachmentAdded');
            document.dispatchEvent(event);
          }
        });
    });
  }

  blobToBase64(blob) {
    return new Promise((resolve, _) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.readAsDataURL(blob);
    });
  }

  async saveAttachment() {
    if (!this.state.selectedFile) return;

    let fileData = await this.blobToBase64(this.state.selectedFile);
    fileData = fileData.split(',')[1];

    let contentType = '';
    contentType = this.state.selectedFile.type;

    const custodian = this.state.ownOrganization;

    let author = this.state.ownPractitionerRole;

    const client = this.context.client;

    client.request('ServiceRequest/' + this.props.sr_id).then(srData => {
      const serviceRequestId = getExternalServiceRequestId(srData, client) ?? this.props.sr_id;

      const resource = {
        resourceType: 'DocumentReference',
        status: 'current',
        docStatus: 'preliminary',
        category: [
          {
            text: this.state.fileCategory === 'Radiology' ? 'Radeology' : this.state.fileCategory,
          },
        ],
        subject: {
          reference: 'Patient/' + this.props.srSubject,
        },
        date: format(new Date(), "yyyy-MM-dd'T'HH:mm:ss.SSSSXXX"),
        author: [
          {
            reference: author,
          },
        ],
        custodian: {
          reference: custodian,
        },
        content: [
          {
            attachment: {
              contentType: contentType,
              data: fileData,
            },
            format: {
              system: 'https://www.hl7.org/fhir/valueset-formatcodes.html',
              code: 'urn:ihe:iti:xds:2017:mimeTypeSufficient',
              display: 'mimeType Sufficient',
            },
          },
        ],
        context: {
          related: [
            {
              reference: 'ServiceRequest/' + serviceRequestId,
            },
          ],
        },
      };

      // create resource
      client
        .request(
          {
            url: `Communication?based-on=ServiceRequest/${serviceRequestId}&_sort=-sent`,
            federatedServerUrl: getExternalServerUrl(srData, client),
          },
          {
            pageLimit: 0,
            flat: true,
          }
        )
        .then(communicationData => {
          let isActiveDraft = communicationData[0] && communicationData[0].status == 'preparation' ? true : false;
          // create resource
          client.create(resource).then(data => {
            if (isActiveDraft) {
              client
                .patch(
                  `Communication/${communicationData[0].id}`,
                  [
                    {
                      op: 'add',
                      path: communicationData[0].payload ? '/payload/-' : '/payload',
                      value: communicationData[0].payload
                        ? {
                            contentReference: {
                              reference: `DocumentReference/${data.id}`,
                            },
                          }
                        : [
                            {
                              contentReference: {
                                reference: `DocumentReference/${data.id}`,
                              },
                            },
                          ],
                    },
                  ],
                  {
                    url: getExternalServerUrl(srData, client),
                    federatedServerUrl: getExternalServerUrl(srData, client),
                  }
                )
                .then(patchedData => {
                  this.props.updateCommunications(communicationData[0].id);
                  const event = new CustomEvent('newAttachmentAdded');
                  document.dispatchEvent(event);
                });
            } else {
              this.createCommunication(true, null, `DocumentReference/${data.id}`);
            }
          });
        });
    });

    // trigger reload of communications
    this.props.reRender();
  }

  async saveDicom(study_id) {
    if (!study_id) {
      return;
    }

    let author = this.state.ownPractitionerRole;
    const client = this.context.client;

    // get current ServiceRequest
    client.request('ServiceRequest/' + this.props.sr_id).then(srData => {
      const serviceRequestId = getExternalServiceRequestId(srData, client) ?? this.props.sr_id;

      // create new ImagingStudy ressource
      const resource = {
        resourceType: 'ImagingStudy',
        status: 'available',
        identifier: {
          use: 'official',
          system: 'urn:dicom:uid',
          value: 'urn:oid:' + study_id,
        },
        subject: {
          reference: 'Patient/' + this.props.srSubject,
        },
        started: format(new Date(), "yyyy-MM-dd'T'HH:mm:ss.SSSSXXX"),
        referrer: [
          {
            reference: author,
          },
        ],
        basedOn: {
          reference: 'ServiceRequest/' + serviceRequestId,
        },
      };

      // get Communications linked to this ServiceRequest
      client
        .request(
          {
            url: `Communication?based-on=ServiceRequest/${serviceRequestId}&_sort=-sent`,
            federatedServerUrl: getExternalServerUrl(srData, client),
          },
          {
            pageLimit: 0,
            flat: true,
          }
        )
        .then(async communicationData => {
          let isActiveDraft = communicationData[0] && communicationData[0].status == 'preparation' ? true : false;
          if (isActiveDraft) {
            let dicomStudyIds = [];
            if (communicationData[0]?.payload !== undefined) {
              let imagingStudies = [];
              communicationData[0].payload.forEach(ref => {
                // com contains an ImagingStudy
                if (ref.contentReference !== undefined && ref.contentReference.reference.startsWith('ImagingStudy')) {
                  imagingStudies.push(ref.contentReference.reference);
                }
              });

              await Promise.all(
                imagingStudies.map(async id => {
                  await client
                    .request(
                      {
                        url: `${id}`,
                        federatedServerUrl: this.props.externalServerUrl,
                      },
                      {
                        pageLimit: 0,
                        flat: true,
                      }
                    )
                    .then(data => {
                      let studyId = data.identifier[0].value.split(':').slice(-1)[0];
                      dicomStudyIds = [...dicomStudyIds, studyId];
                      return dicomStudyIds;
                    });
                })
              );
            }

            // if there are studies with the same id already present in the draft, do not create a new resource
            if (dicomStudyIds.filter(o => o == study_id).length === 0) {
              // create Communication
              client.create(resource).then(data => {
                // is there an active draft Communication?, then add the ImagingStudy to this Communication
                client
                  .patch(
                    `Communication/${communicationData[0].id}`,
                    [
                      {
                        op: 'add',
                        path: communicationData[0].payload ? '/payload/-' : '/payload',
                        value: communicationData[0].payload
                          ? {
                              contentReference: {
                                reference: `ImagingStudy/${data.id}`,
                              },
                            }
                          : [
                              {
                                contentReference: {
                                  reference: `ImagingStudy/${data.id}`,
                                },
                              },
                            ],
                      },
                    ],
                    {
                      url: getExternalServerUrl(srData, client),
                      federatedServerUrl: getExternalServerUrl(srData, client),
                    }
                  )
                  .then(patchedData => {
                    this.props.updateCommunications(communicationData[0].id);
                    const event = new CustomEvent('newAttachmentAdded');
                    document.dispatchEvent(event);
                  });
              });
            }
          } else {
            client.create(resource).then(data => {
              this.createCommunication(true, null, `ImagingStudy/${data.id}`);
            });
          }
        });
    });

    // trigger reload of communications
    this.props.reRender();
  }

  render() {
    const { t } = this.props;

    const canCloseCase = this.props.srIsOnHoldBy != this.state.ownOrganization ? true : false;
    const disableCloseCase = !canCloseCase || this.props.srIsArchived;
    const readonly = this.props.srIsOnHoldBy ? true : false || this.props.srIsArchived;
    const videoconf = readonly || this.props.videoconfActive;

    const videoconferenceButton = !this.state.videoconferencesEnabled ? null : (
      <Button variant="contained" color="primary" onClick={this.onStartVideoconferenceButtonClick} disabled={videoconf}>
        {t('Start Videoconference')}
      </Button>
    );

    const dicomUploadButton = !this.state.dicomEnabled ? null : (
      <Button variant="contained" color="primary" onClick={this.onDicomButtonClick} disabled={readonly}>
        {t('Upload Dicom Image')}
      </Button>
    );

    return (
      <div className="caseFooter">
        <ButtonGroup>
          <Button variant="contained" color="primary" onClick={this.onRequestButtonClick} disabled={readonly}>
            {t('New message')}
          </Button>
          <Button variant="contained" color="primary" onClick={this.onAttachmentButtonClick} disabled={readonly}>
            {t('Add attachment')}
          </Button>
          {dicomUploadButton}
          {videoconferenceButton}
          <Button
            variant="contained"
            color="primary"
            onClick={this.onCloseConsultationButtonClick}
            disabled={disableCloseCase}
          >
            {t('Close consultation')}
          </Button>
          <PdfExport srIsArchived={this.props.srIsArchived} srId={this.props.sr_id}></PdfExport>
        </ButtonGroup>

        <Dialog open={this.state.dialogRequestOpen} onClose={this.onRequestDialogClose} maxWidth="sm" fullWidth>
          <div>
            <DialogTitle>{t('Request')}</DialogTitle>
            <DialogContent dividers>
              <DialogContentText>{t('Send request with following data:')}</DialogContentText>
              <TextareaAutosize
                autoFocus
                minRows={10}
                style={{
                  width: 400,
                  maxWidth: 550,
                  maxHeight: 600,
                  fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
                  fontSize: 'inherit',
                  resize: 'none',
                }}
                id="message"
                placeholder={t('Your message')}
                type="text"
                onChange={this.onTextfieldChange}
                value={this.state.message}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={this.onRequestDialogClose} color="primary">
                {t('Cancel')}
              </Button>
              <Button onClick={this.onSaveRequestAsDraft} color="primary">
                {t('Save as draft')}
              </Button>
              <Button onClick={this.onSendRequest} color="primary">
                {t('Send')}
              </Button>
            </DialogActions>
          </div>
        </Dialog>

        <Dialog open={this.state.dialogAttachmentOpen} onClose={this.onAttachmentDialogClose} className="form">
          <div>
            <DialogTitle>{t('Attachments')}</DialogTitle>
            <DialogContent>
              <FormControl>
                <Select id="fileCategory" value={this.state.fileCategory} onChange={this.onFileCategoryChange}>
                  <MenuItem value={this.fileType[0]}>{t(this.fileType[0])}</MenuItem>
                  <MenuItem value={this.fileType[1]}>{t(this.fileType[1])}</MenuItem>
                  <MenuItem value={this.fileType[2]}>{t(this.fileType[2])}</MenuItem>
                  <MenuItem value={this.fileType[3]}>{t(this.fileType[3])}</MenuItem>
                  <MenuItem value={this.fileType[4]}>{t(this.fileType[4])}</MenuItem>
                  <MenuItem value={this.fileType[5]}>{t(this.fileType[5])}</MenuItem>
                  <MenuItem value={this.fileType[6]}>{t(this.fileType[6])}</MenuItem>
                  <MenuItem value={this.fileType[7]}>{t(this.fileType[7])}</MenuItem>
                  <MenuItem value={this.fileType[8]}>{t(this.fileType[8])}</MenuItem>
                  <MenuItem value={this.fileType[9]}>{t(this.fileType[9])}</MenuItem>
                  <MenuItem value={this.fileType[10]}>{t(this.fileType[10])}</MenuItem>
                </Select>
                <FormHelperText>{t('File category')}</FormHelperText>
              </FormControl>
              <DialogContentText>{t('Upload your file:')}</DialogContentText>
              <span className="files text">{t('or drag it here.')}</span>
              <div className="files input">
                <input type="file" multiple="" onChange={this.onFileChangeHandler} />
              </div>
              <img src="/upload.png" className="files image" />
              <div className="files error">{this.state.fileError}</div>
            </DialogContent>
            <DialogActions>
              <Button onClick={this.onAttachmentDialogClose} color="primary">
                {t('Cancel')}
              </Button>
              <Button onClick={this.onSaveAttachment} color="primary" disabled={!this.state.fileUploadAllowed}>
                {t('Save')}
              </Button>
            </DialogActions>
          </div>
        </Dialog>

        <DicomUploadDialog
          open={this.state.dialogDicomOpen}
          onClose={this.onDicomDialogClose}
          saveDicom={this.saveDicom}
          className="form"
        />

        <Dialog
          open={this.state.confirmationOpen}
          onClose={this.onConfirmationDialogClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{this.state.confirmationTitle}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">{this.state.confirmationText}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.onConfirmationDisagree} color="primary">
              {this.state.confirmationDisagree}
            </Button>
            <Button onClick={this.onConfirmationAgree} color="primary" autoFocus>
              {this.state.confirmationAgree}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default withTranslation()(CaseFooter);

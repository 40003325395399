import React from 'react';
import { FhirClientContext } from '../../FhirClientContext';
import { withTranslation } from 'react-i18next';
import FsLightbox from 'fslightbox-react';
import { TailSpin } from 'react-loader-spinner';

class Media extends React.Component {
  static contextType = FhirClientContext;

  constructor(props) {
    super(props);
    this.state = {
      imageData: [],
      toggler: false,
      selectedId: '',
      slide: 1,
    };
  }

  loadData() {
    const client = this.context.client;
    this.props.ids.map(id => {
      client
        .request(
          { url: `Media/${id}`, federatedServerUrl: this.props.externalServerUrl },
          {
            pageLimit: 0,
            flat: true,
          }
        )
        .then(data => {
          this.setState({
            imageData: [...this.state.imageData, { data: data.content.data, id: id }],
          });
        });
    });
  }

  componentWillUnmount() {}

  componentDidMount() {
    this.loadData();
  }

  render() {
    const lightbox = (
      <FsLightbox
        toggler={this.state.toggler}
        sources={this.state.imageData.map(image => (image = <img src={'data:image/png;base64,' + image.data} />))}
        slide={this.state.slide}
        key={this.state.imageData.id}
      />
    );

    const selectImage = imageId => {
      let imageIndex = this.state.imageData.findIndex(o => o.id == imageId) + 1;
      this.setState({ toggler: !this.state.toggler, selectedId: imageId, slide: imageIndex });
    };

    return (
      <div className="mediaElement" style={{ display: 'flex', flexDirection: 'row' }}>
        {this.props.ids.map((id, index) => (
          <div key={index}>
            {this.state.imageData[index] ? (
              <img
                src={'data:image/png;base64,' + this.state.imageData[index].data}
                width="100px"
                height="100px"
                style={{ paddingRight: '.5em' }}
                onClick={() => selectImage(this.state.imageData[index].id)}
              />
            ) : (
              <div
                style={{
                  width: '50px',
                  height: '50px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  paddingRight: '.5em',
                }}
              >
                <TailSpin
                  ariaLabel="loading-indicator"
                  color="#21264e"
                  height="20"
                  width="20"
                  style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                />
              </div>
            )}
          </div>
        ))}
        {lightbox}
      </div>
    );
  }
}

export default withTranslation()(Media);

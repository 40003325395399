import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import Button from '@material-ui/core/Button';
import DropdownMenu from './DropdownMenu';
import { getVectorConfig } from '../utils/getConfig';
import { ProSidebar, SidebarContent, SidebarFooter, SidebarHeader } from 'react-pro-sidebar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faHome,
  faArchive,
  faPlus,
  faNotesMedical,
  faSignOutAlt,
  faShieldAlt,
  faInfoCircle,
  faUserCog,
} from '@fortawesome/free-solid-svg-icons';
import ButtonGroup from '@mui/material/ButtonGroup';
import { Divider } from '@mui/material';

function Home(props) {
  const { t } = props;
  const [menuCollapse, setMenuCollapse] = React.useState(false);
  const [baseUrl, setBaseUrl] = React.useState('');
  const [config, setConfig] = React.useState('');

  useEffect(() => {
    const getConfig = async () => {
      const config = await getVectorConfig('/config');
      setConfig(config);
    };

    getConfig().catch(console.error);

    if (!baseUrl) setBaseUrl(window.location.origin);
  }, []);

  const logOutUser = () => {
    window.location.replace(config.auth_url + '/protocol/openid-connect/logout?redirect_uri=' + baseUrl);
  };

  const menuCollapseClick = () => {
    menuCollapse ? setMenuCollapse(false) : setMenuCollapse(true);
    localStorage.setItem('menuCollapse', !menuCollapse);
  };

  return (
    <ProSidebar
      collapsed={menuCollapse}
      style={{ height: '100vh', top: '0px', left: '0px', borderRight: '1px solid #adadad33' }}
    >
      <SidebarHeader>
        <div style={{ paddingBottom: '15px', paddingTop: '15px' }}>
          {menuCollapse ? (
            <a href="https://amp.clinic" target="_blank">
              <img src="/ampclinic_owl.png" width="100%" />
            </a>
          ) : (
            <a href="https://amp.clinic" target="_blank">
              <img src="/ampclinic_logo.png" width="100%" />
            </a>
          )}
        </div>
      </SidebarHeader>
      <SidebarContent style={{ paddingTop: '30px' }}>
        <ButtonGroup orientation="vertical" aria-label="vertical contained button group">
          <Link to="/app" className="menuButton">
            <Button color="primary" className="menuButtonClass" title={t('Home')}>
              <FontAwesomeIcon className="sidebarIcon" icon={faHome} style={{ paddingRight: '4px' }}></FontAwesomeIcon>
              <span>{menuCollapse ? '' : t('Home')}</span>
            </Button>
          </Link>
          <br />
          <Link to="/new" className="menuButton">
            <Button
              color="primary"
              className="menuButtonClass createConsultationButton"
              title={t('Create new consultation')}
            >
              <FontAwesomeIcon className="sidebarIcon" icon={faPlus} style={{ paddingRight: '4px' }}></FontAwesomeIcon>
              <span>{menuCollapse ? '' : t('Create')}</span>
            </Button>
          </Link>
          <br />
          <Link to="/cases/active" className="menuButton">
            <Button color="primary" className="menuButtonClass" title={t('Consils')}>
              <FontAwesomeIcon
                className="sidebarIcon"
                icon={faNotesMedical}
                style={{ paddingRight: '4px' }}
              ></FontAwesomeIcon>
              <span>{menuCollapse ? '' : t('Consils')}</span>
            </Button>
          </Link>
          <br />
          <Link to="/cases/archive" className="menuButton">
            <Button color="primary" className="menuButtonClass" title={t('Archive')}>
              <FontAwesomeIcon
                className="sidebarIcon"
                icon={faArchive}
                style={{ paddingRight: '4px' }}
              ></FontAwesomeIcon>
              <span>{menuCollapse ? '' : t('Archive')}</span>
            </Button>
          </Link>
          <br />
          <DropdownMenu className="menuButton" t={t} menuCollapse={menuCollapse}></DropdownMenu>
          <br />
          <Link to={{ pathname: `${config.auth_url + '/account'}` }} className="menuButton" target="_blank">
            <Button color="secondary" className="menuButtonClass" title={t('Settings')}>
              <FontAwesomeIcon
                className="sidebarIcon"
                icon={faUserCog}
                style={{ paddingRight: '4px' }}
              ></FontAwesomeIcon>
              <span>{menuCollapse ? '' : t('Settings')}</span>
            </Button>
          </Link>
          <br />
          <Link to="/" className="menuButton">
            <Button color="secondary" className="menuButtonClass" title={t('Logout')} onClick={logOutUser}>
              <FontAwesomeIcon
                className="sidebarIcon"
                icon={faSignOutAlt}
                style={{ paddingRight: '4px' }}
              ></FontAwesomeIcon>
              <span>{menuCollapse ? '' : t('Logout')}</span>
            </Button>
          </Link>
          <br />
          <Divider sx={{ borderColor: '#adadad33' }}></Divider>
          <br />
          <Link to="/privacy" className="menuButton">
            <Button color="secondary" className="menuButtonClass alignButtonLeft" title={t('Privacy statement')}>
              <FontAwesomeIcon
                className="sidebarIcon"
                icon={faShieldAlt}
                style={{ paddingRight: '4px' }}
              ></FontAwesomeIcon>
              <span>{menuCollapse ? '' : t('Privacy statement')}</span>
            </Button>
          </Link>
          <br />
          <Link to="/legal" className="menuButton">
            <Button color="secondary" className="menuButtonClass" title={t('Legal notice')}>
              <FontAwesomeIcon
                className="sidebarIcon"
                icon={faInfoCircle}
                style={{ paddingRight: '4px' }}
              ></FontAwesomeIcon>
              <span>{menuCollapse ? '' : t('Legal notice')}</span>
            </Button>
          </Link>
        </ButtonGroup>
      </SidebarContent>
      <SidebarFooter style={{ padding: '20px 0px' }}>
        <div className="amp" style={{ textAlign: 'center' }} onClick={menuCollapseClick}>
          {menuCollapse ? (
            <FontAwesomeIcon className="sidebarIcon" icon={faAngleDoubleRight}></FontAwesomeIcon>
          ) : (
            <FontAwesomeIcon className="sidebarIcon" icon={faAngleDoubleLeft}></FontAwesomeIcon>
          )}
        </div>
      </SidebarFooter>
    </ProSidebar>
  );
}

export default withTranslation()(Home);

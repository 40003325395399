import { useState } from 'react';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import i18n from './../../i18n';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGlobeEurope } from '@fortawesome/free-solid-svg-icons';

export default function DropdownMenu({ t, menuCollapse }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const changeLanguage = lng => {
    const event = new CustomEvent('langChange', { detail: lng });
    i18n.changeLanguage(lng);
    document.dispatchEvent(event);
    handleClose();
  };

  return (
    <div>
      <Button
        id="basic-button"
        aria-controls="basic-menu"
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        color="primary"
        className="menuButtonClass"
        title={t('Change language')}
      >
        <FontAwesomeIcon className="sidebarIcon" icon={faGlobeEurope} style={{ paddingRight: '4px' }}></FontAwesomeIcon>
        <span>{menuCollapse ? '' : t('Language')}</span>
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem style={{ fontFamily: 'SourceSansPro' }} onClick={() => changeLanguage('en')}>
          {t('English')}
        </MenuItem>
        <MenuItem style={{ fontFamily: 'SourceSansPro' }} onClick={() => changeLanguage('de')}>
          {t('German')}
        </MenuItem>
      </Menu>
    </div>
  );
}

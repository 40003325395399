import React from 'react';
import { withTranslation } from 'react-i18next';
import FhirClientProvider from '../structures/FhirClientProvider';
import Stats from '../elements/Stats';

class Home extends React.Component {
  render() {
    return (
      <FhirClientProvider>
        <Stats></Stats>
      </FhirClientProvider>
    );
  }
}

export default withTranslation()(Home);

import { Fragment } from 'react';
import { Text, View, StyleSheet, Font } from '@react-pdf/renderer';
import PdfCommunicationImage from './PdfCommunicationImage';
import colors from '../../../css/theme.module.scss';
import font from './../../../assets/fonts/SourceSansPro-Regular.ttf';

const styles = StyleSheet.create({
  text: {
    marginTop: 5,
    marginBottom: 5,
    marginLeft: 10,
    marginRight: 10,
    fontSize: 14,
    textAlign: 'justify',
    fontFamily: 'SourceSansPro',
    color: colors.ampDarkBlue,
  },
  statusView: {
    borderBottomLeftRadius: '13px',
    borderBottomRightRadius: '13px',
    display: 'flex',
    alignItems: 'center',
    margin: -0.5,
  },
  headerText: {
    marginTop: 5,
    marginBottom: 5,
    fontSize: 14,
    fontFamily: 'SourceSansPro',
    color: colors.sidebarBackground,
  },
  headerView: {
    borderTopRightRadius: '13px',
    borderTopLeftRadius: '13px',
    backgroundColor: colors.ampDarkBlue,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: -0.5,
  },
});

const PdfCommunicationData = ({ communicationData, t }) => {
  Font.register({ family: 'SourceSansPro', src: font });

  const com = communicationData.map(communication => (
    <View
      key={communication.id}
      wrap={false}
      style={{ border: '3px solid ' + communication.statusBorderColor, borderRadius: '15px', marginTop: '10px' }}
    >
      <View style={styles.headerView}>
        <Text style={[styles.headerText, { marginLeft: 10 }]}>{communication.sent}</Text>
        <Text style={[styles.headerText, { marginRight: 10 }]}>{communication.sender.name}</Text>
      </View>
      <Text style={styles.text}>
        {communication.duration ? t('Call duration') + ': ' + communication.duration : null}
      </Text>
      <PdfCommunicationImage imgData={communication.payload}></PdfCommunicationImage>
      <View style={{ backgroundColor: colors.sidebarBackground }}>
        <Text style={styles.text}>{communication.note ? communication.note[0].text : null}</Text>
      </View>
      <View style={[styles.statusView, { backgroundColor: communication.statusColor }]}>
        <Text style={[styles.text]}>{t(communication.statusText)}</Text>
      </View>
    </View>
  ));
  return <Fragment>{com}</Fragment>;
};

export default PdfCommunicationData;

import React from 'react';
import { FhirClientContext } from '../../FhirClientContext';
import { withTranslation } from 'react-i18next';
import { getVectorConfig } from '../utils/getConfig';
import { JitsiMeeting } from '@jitsi/react-sdk';

class VideoConference extends React.Component {
  static contextType = FhirClientContext;
  api = {};

  constructor(props) {
    super(props);
    this.state = {
      jitsiDomain: ''
    };
  }

  async componentDidMount() {
    const config = await getVectorConfig('/config');

    const client = this.context.client;
    await client.refreshIfNeeded();
    const jwt = client.getAuthorizationHeader().substr(client.getAuthorizationHeader().indexOf(' ') + 1);

    this.setState({ jitsiDomain: config.jitsiDomain, jwt: jwt });
  }

  handleVideoConferenceLeft = () => {
    console.log('handleVideoConferenceLeft');
    this.props.showVideoconf(false);
  };

  render() {
    let jitsiContainer = null;

    if (this.state.jitsiDomain) {
      jitsiContainer = (
        <JitsiMeeting
          domain={this.state.jitsiDomain}
          roomName={this.props.roomName}
          jwt={this.state.jwt}
          userInfo={{
            displayName: this.props.userName,
          }}
          onApiReady={externalApi => {
            // here you can attach custom event listeners to the Jitsi Meet External API
            // you can also store it locally to execute commands
          }}
          getIFrameRef={iframeRef => {
            iframeRef.style.height = '400px';
          }}
          onReadyToClose={this.handleVideoConferenceLeft}
          configOverwrite={{
            startWithAudioMuted: true,
            //disableModeratorIndicator: true,
            enableEmailInStats: false,
            enableWelcomePage: false,
            enableCalendarIntegration: false,
            disableThirdPartyRequests: true,
            //gravatar: {
            //  disabled: true
            //},
            liveStreaming: {
              enabled: false,
            },
            transcription: {
              enabled: false,
            },
            enableWelcomePage: false,
            defaultRemoteDisplayName: 'Unbekannter Teilnehmer',
            gatherStats: false,
            analytics: {
              disabled: true,
            },
          }}
          interfaceConfigOverwrite={{
            DISABLE_JOIN_LEAVE_NOTIFICATIONS: true,
            SHOW_BRAND_WATERMARK: false,
            JITSI_WATERMARK_LINK: 'https://amp.clinic',
          }}
        />
      );
    }

    return (
      <>
        <div id="jitsi-iframe">{jitsiContainer}</div>
      </>
    );
  }
}

export default withTranslation()(VideoConference);

import React from 'react';
import { withTranslation } from 'react-i18next';
import Button from '@material-ui/core/Button';
import { FhirClientContext } from '../../FhirClientContext';
import FsLightbox from 'fslightbox-react';
import './../../css/views/ImageCategory.scss';
import Badge from '@mui/material/Badge';
import { getExternalServerUrl, getExternalServiceRequestId } from '../utils/checkExternalReference';

import Anamnesis from './../../assets/images/TabAnamnesisIcon.svg';
import BloodGasAnalysis from './../../assets/images/TabBloodGasAnalysisIcon.svg';
import Haemodynamics from './../../assets/images/TabHaemodynamicsIcon.svg';
import InfectiousDisease from './../../assets/images/TabInfectiousDiseaseIcon.svg';
import Lab from './../../assets/images/TabLabIcon.svg';
import MedicalLetter from './../../assets/images/TabMedicalLetterIcon.svg';
import Others from './../../assets/images/TabOthersIcon.svg';
import Perfusors from './../../assets/images/TabPerfusorsIcon.svg';
import Radiology from './../../assets/images/TabRadiologyIcon.svg';
import Respiration from './../../assets/images/TabRespirationIcon.svg';

class ImageCategory extends React.Component {
  static contextType = FhirClientContext;
  constructor(props) {
    super(props);
    this.state = {
      toggler: false,
      selectedCategory: '',
      docRefs: [],
      imageCategoryList: {
        Anamnesis: [],
        MedicalLetter: [],
        Haemodynamics: [],
        Respiration: [],
        BloodGasAnalysis: [],
        Perfusors: [],
        InfectiousDisease: [],
        Radeology: [],
        Lab: [],
        Others: [],
      },
      categoryChanged: false,
      externalServerUrl: false,
    };

    this.categories = [
      'Anamnesis',
      'MedicalLetter',
      'Haemodynamics',
      'Respiration',
      'BloodGasAnalysis',
      'Perfusors',
      'InfectiousDisease',
      'Radeology',
      'Lab',
      'Others',
    ];

    this.toggleLightbox = this.toggleLightbox.bind(this);
  }

  loadData = async () => {
    const client = this.context.client;
    let categories;

    await client
      .request(`ServiceRequest/${this.props.srId}`, {
        pageLimit: 0,
        flat: true,
      })
      .then(async srData => {
        this.setState({ externalServerUrl: getExternalServerUrl(srData, client) });
      });

    await Promise.all(
      this.state.docRefs.map(async docRef => {
        return await client
          .request(
            { url: `${docRef}`, federatedServerUrl: this.state.externalServerUrl },
            {
              pageLimit: 0,
              flat: true,
            }
          )
          .then(async data => {
            let currentCategories = {
              Anamnesis: [],
              MedicalLetter: [],
              Haemodynamics: [],
              Respiration: [],
              BloodGasAnalysis: [],
              Perfusors: [],
              InfectiousDisease: [],
              Radeology: [],
              Lab: [],
              Others: [],
            };

            await client
              .request({
                url: `${docRef}/$binary-access-read?path=DocumentReference.content.attachment`,
                federatedServerUrl: this.state.externalServerUrl,
              })
              .then(blobData => blobData.blob())
              .then(async blob => {
                let imageB64 = await this.blobToBase64(blob);
                await client
                  .request({
                    url: `DocumentReference?relatesto=${docRef}`,
                    federatedServerUrl: this.state.externalServerUrl,
                  })
                  .then(linkedFullResDocRef => {
                    let linkedDocRefId = '';
                    let isVideo = imageB64.includes('video');
                    if (linkedFullResDocRef.entry) {
                      linkedDocRefId =
                        linkedFullResDocRef.entry[0].resource.resourceType +
                        '/' +
                        linkedFullResDocRef.entry[0].resource.id;
                    }
                    if (currentCategories[data.category[0].text] != undefined) {
                      categories = {
                        ...currentCategories,
                        [data.category[0].text]: [
                          ...currentCategories[data.category[0].text],
                          { data: imageB64, linkedDocRefId: linkedDocRefId, fullRes: null, isVideo: isVideo },
                        ],
                      };
                    }
                  });
              });

            return categories;
          });
      })
    ).then(categories => {
      let imgCategories = {
        Anamnesis: [],
        MedicalLetter: [],
        Haemodynamics: [],
        Respiration: [],
        BloodGasAnalysis: [],
        Perfusors: [],
        InfectiousDisease: [],
        Radeology: [],
        Lab: [],
        Others: [],
      };

      let categoryKeys = Object.keys(imgCategories);
      categories.map(promiseData => {
        for (var i = 0; i < categoryKeys.length; i++) {
          if (imgCategories[categoryKeys[i]] == undefined || imgCategories[categoryKeys[i]].length == 0)
            imgCategories[categoryKeys[i]] = promiseData[categoryKeys[i]];
          else {
            imgCategories[categoryKeys[i]] = [...imgCategories[categoryKeys[i]], ...promiseData[categoryKeys[i]]];
          }
        }
      });

      this.setState({ imageCategoryList: imgCategories });
    });
  };

  blobToBase64 = blob =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });

  getImageVariable(category) {
    switch (category) {
      case 'Anamnesis':
        return Anamnesis;
      case 'MedicalLetter':
        return MedicalLetter;
      case 'Haemodynamics':
        return Haemodynamics;
      case 'Respiration':
        return Respiration;
      case 'BloodGasAnalysis':
        return BloodGasAnalysis;
      case 'Perfusors':
        return Perfusors;
      case 'InfectiousDisease':
        return InfectiousDisease;
      case 'Radeology':
        return Radiology;
      case 'Lab':
        return Lab;
      case 'Others':
        return Others;
    }
  }

  getImageData(category) {
    switch (category) {
      case 'Anamnesis':
        return this.state.imageCategoryList.Anamnesis.map(
          o => (o = o.isVideo ? <video src={o.fullRes ?? o.data} controls /> : <img src={o.fullRes ?? o.data} />)
        );
      case 'MedicalLetter':
        return this.state.imageCategoryList.MedicalLetter.map(
          o => (o = o.isVideo ? <video src={o.fullRes ?? o.data} controls /> : <img src={o.fullRes ?? o.data} />)
        );
      case 'Haemodynamics':
        return this.state.imageCategoryList.Haemodynamics.map(
          o => (o = o.isVideo ? <video src={o.fullRes ?? o.data} controls /> : <img src={o.fullRes ?? o.data} />)
        );
      case 'Respiration':
        return this.state.imageCategoryList.Respiration.map(
          o => (o = o.isVideo ? <video src={o.fullRes ?? o.data} controls /> : <img src={o.fullRes ?? o.data} />)
        );
      case 'BloodGasAnalysis':
        return this.state.imageCategoryList.BloodGasAnalysis.map(
          o => (o = o.isVideo ? <video src={o.fullRes ?? o.data} controls /> : <img src={o.fullRes ?? o.data} />)
        );
      case 'Perfusors':
        return this.state.imageCategoryList.Perfusors.map(
          o => (o = o.isVideo ? <video src={o.fullRes ?? o.data} controls /> : <img src={o.fullRes ?? o.data} />)
        );
      case 'InfectiousDisease':
        return this.state.imageCategoryList.InfectiousDisease.map(
          o => (o = o.isVideo ? <video src={o.fullRes ?? o.data} controls /> : <img src={o.fullRes ?? o.data} />)
        );
      case 'Radeology':
        return this.state.imageCategoryList.Radeology.map(
          o => (o = o.isVideo ? <video src={o.fullRes ?? o.data} controls /> : <img src={o.fullRes ?? o.data} />)
        );
      case 'Lab':
        return this.state.imageCategoryList.Lab.map(
          o => (o = o.isVideo ? <video src={o.fullRes ?? o.data} controls /> : <img src={o.fullRes ?? o.data} />)
        );
      case 'Others':
        return this.state.imageCategoryList.Others.map(
          o => (o = o.isVideo ? <video src={o.fullRes ?? o.data} controls /> : <img src={o.fullRes ?? o.data} />)
        );
    }
  }

  preloadDocumentReferences = async () => {
    const client = this.context.client;
    this.setState({ docRefs: [] });

    await client
      .request(`ServiceRequest/${this.props.srId}`, {
        pageLimit: 0,
        flat: true,
      })
      .then(async srData => {
        await client
          .request(
            {
              url: `Communication?based-on=ServiceRequest/${
                getExternalServiceRequestId(srData, client) ?? this.props.srId
              }`,
              federatedServerUrl: getExternalServerUrl(srData, client),
            },
            {
              pageLimit: 0,
              flat: true,
            }
          )
          .then(data => {
            data.map(o => {
              if (client.getPath(o, 'payload') !== undefined) {
                client.getPath(o, 'payload').forEach(ref => {
                  if (ref.contentReference !== undefined && ref.contentReference.reference.startsWith("DocumentReference")) {
                    this.setState({
                      docRefs: [...this.state.docRefs, ref.contentReference.reference],
                    });
                  }
                });
              }
            });
          });
      });
  };

  async componentDidMount() {
    await this.preloadDocumentReferences();
    await this.loadData();

    document.addEventListener('newAttachmentAdded', async () => {
      await this.preloadDocumentReferences();
      await this.loadData();
    });
  }

  componentWillUnmount() {}

  toggleLightbox(e) {
    this.setState({
      selectedCategory: e.currentTarget.getAttribute('category'),
      categoryChanged: true,
      toggler: !this.state.toggler,
    });
  }

  loadFullResPicture = async imageIndex => {
    const client = this.context.client;
    let imgCategoryList = this.state.imageCategoryList;
    let imageArray = [...this.state.imageCategoryList[this.state.selectedCategory]];
    let imageId = imageArray[imageIndex].linkedDocRefId;

    if (imageId !== '') {
      await client
        .request(`${imageId}/$binary-access-read?path=DocumentReference.content.attachment`)
        .then(data => {
          if (data) {
            return data.blob();
          } else return;
        })
        .then(async blob => {
          let fullResB64 = await this.blobToBase64(blob);
          imageArray[imageIndex].fullRes = fullResB64;
          imgCategoryList[this.state.selectedCategory] = imageArray;
          this.setState({ ...this.state.imageCategoryList, imgCategoryList });
        })
        .catch(err => console.log(err));
    }
  };

  render() {
    const { t, i18n } = this.props;
    const sidebar = this.categories.map((category, index) => (
      <div key={index++}>
        <Button
          key={category}
          category={category}
          onClick={this.toggleLightbox}
          disabled={this.getImageData(category).length == 0}
        >
          <div className="amp" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Badge badgeContent={this.getImageData(category).length} color="primary" className="amountBadge">
              <img src={this.getImageVariable(category)} style={{ width: '50px', height: '50px' }} />
            </Badge>
            <span style={{ fontSize: 10 }}>{t(category)}</span>
          </div>
        </Button>
      </div>
    ));

    const lightbox = (
      <FsLightbox
        toggler={this.state.toggler}
        sources={this.getImageData(this.state.selectedCategory)}
        thumbs={this.state.imageCategoryList[this.state.selectedCategory]?.map(o => o.data)}
        key={this.state.selectedCategory}
        openOnMount={this.state.categoryChanged}
        onSlideChange={slide => this.loadFullResPicture(slide.stageIndexes.current)}
        showThumbsOnMount={true}
      />
    );

    return (
      <div
        className="rightMenu"
        style={{
          height: '100%',
          right: '0px',
          justifyContent: 'space-evenly',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {sidebar}
        {lightbox}
      </div>
    );
  }
}

export default withTranslation()(ImageCategory);

import React from 'react';
import { withTranslation } from 'react-i18next';
import { FhirClientContext } from '../../../FhirClientContext';
import Button from '@material-ui/core/Button';
import { Page, Text, View, Document, StyleSheet, pdf, Image, Font } from '@react-pdf/renderer';
import * as JSZip from 'jszip';
import { saveAs } from 'file-saver';
import PdfCommunicationData from './PdfCommunicationData';
import PdfPatientHeaderData from './PdfPatientHeaderData';
import { format, parseISO, formatDistanceStrict, isValid } from 'date-fns';
import { de, enUS } from 'date-fns/locale';
import colors from './../../../css/theme.module.scss';
const version = require('../../../../package.json').version;
import font from './../../../assets/fonts/SourceSansPro-Regular.ttf';
import { getOwnOrganization } from '../../utils/getOwn';
import { getExternalServiceRequestId, getExternalServerUrl } from '../../utils/checkExternalReference';

class PdfExport extends React.Component {
  static contextType = FhirClientContext;
  constructor(props) {
    super(props);

    promiseCollection: [];
    this.state = {
      coms: [],
      patient: {
        id: 0,
        name: '',
        height: '',
        birthdate: '',
        gender: '',
        weight: '',
      },
      case: {
        payer: '',
        partner: '',
        counterpart: '',
        callbacknumber: '',
        requester: '',
        consultParticipant: '',
      },
      ownOrganization: '',
      language: localStorage.getItem('i18nextLng') ?? 'en',
      externalServerUrl: '',
    };
  }

  loadData = async () => {
    const client = this.context.client;
    const { t } = this.props;
    Font.register({ family: 'SourceSansPro', src: font });

    // get own organization
    await getOwnOrganization(client);

    // get header data

    await client
      .request(
        { url: 'ServiceRequest/' + this.props.srId, federatedServerUrl: this.state.externalServerUrl },
        {
          pageLimit: 0,
          flat: true,
          resolveReferences: ['performer', 'requester', 'subject', 'contact'],
        }
      )
      .then(async data => {
        this.setState({ externalServerUrl: getExternalServerUrl(data, client) });
        // get patient name
        const name = client.getPath(data, 'subject.name');
        let entry = name.find(nameRecord => nameRecord.use === 'official') || name[0];
        let patientName = '';
        if (!entry) {
          patientName = t('No Name');
        } else {
          patientName = entry.given.join(' ') + ' ' + entry.family;
        }

        // am I requester or performer?
        let partner;

        // get requester organization
        let requesterOrg;
        if (client.getPath(data, 'requester.resourceType') == 'Organization') {
          requesterOrg = 'Organization/' + client.getPath(data, 'requester.id');
        } else {
          requesterOrg = client.getPath(data, 'requester.organization.reference');
        }

        if (requesterOrg == this.state.ownOrganization) {
          // I am requester
          // TODO what if there are multiple performers?
          client
            .request(
              { url: `${requesterOrg}`, federatedServerUrl: this.state.externalServerUrl },
              {
                pageLimit: 0,
                flat: true,
              }
            )
            .then(partner => {
              this.setState({
                case: {
                  ...this.state.case,
                  partner: client.getPath(data, 'performer.0.name'),
                  consultParticipant: partner.name,
                },
              });
            });
        } else {
          // I am performer
          partner = client.getPath(data, 'requester.name');
          client
            .request(
              { url: `${requesterOrg}`, federatedServerUrl: this.state.externalServerUrl },
              {
                pageLimit: 0,
                flat: true,
              }
            )
            .then(partner => {
              this.setState({
                case: {
                  ...this.state.case,
                  partner: partner.name,
                  consultParticipant: client.getPath(data, 'performer.0.name'),
                },
              });
            });
        }

        if (client.getPath(data, 'requester.practitioner.reference')) {
          await client
            .request(
              {
                url: `PractitionerRole?practitioner=${client.getPath(data, 'requester.practitioner.reference')}`,
                federatedServerUrl: this.state.externalServerUrl,
              },
              {
                pageLimit: 0,
                flat: true,
                resolveReferences: ['organization'],
              }
            )
            .then(data => {
              this.setState({ case: { ...this.state.case, requester: data[0].organization.name } });
            });
        }

        await client
          .request(
            {
              url: `Observation?subject=Patient/${client.getPath(data, 'subject.id')}`,
              federatedServerUrl: getExternalServerUrl(data, client),
            },
            {
              pageLimit: 0,
              flat: true,
            }
          )
          .then(data => {
            data.forEach(o => {
              // get weight
              if (client.getPath(o, 'code.coding.0.code') == '29463-7') {
                this.setState({
                  patient: {
                    ...this.state.patient,
                    weight: client.getPath(o, 'valueQuantity.value') + ' ' + client.getPath(o, 'valueQuantity.unit'),
                  },
                });
              }

              // get height
              if (client.getPath(o, 'code.coding.0.code') == '8302-2') {
                this.setState({
                  patient: {
                    ...this.state.patient,
                    height: client.getPath(o, 'valueQuantity.value') + ' ' + client.getPath(o, 'valueQuantity.unit'),
                  },
                });
              }
            });
          });

        await client
          .request(
            {
              url: `Coverage?policy-holder=Patient/${client.getPath(data, 'subject.id')}`,
              federatedServerUrl: getExternalServerUrl(data, client),
            },
            {
              pageLimit: 0,
              flat: true,
            }
          )
          .then(data => {
            this.setState({
              case: {
                ...this.state.case,
                payer: client.getPath(data, '0.class.0.name'),
              },
            });
          });

        this.setState({
          patient: {
            ...this.state.patient,
            id: client.getPath(data, 'subject.id'),
            name: patientName,
            gender: t(client.getPath(data, 'subject.gender')),
            birthdate: format(parseISO(client.getPath(data, 'subject.birthDate')), 'P', { locale: de }),
          },
          case: {
            ...this.state.case,
            counterpart: client.getPath(data, 'subject.contact.0.name.family'),
            callbacknumber: client.getPath(data, 'subject.contact.0.telecom.0.value'),
          },
        });
      });

    let serviceRequestData = await client.request('ServiceRequest/' + this.props.srId).then(async srData => {
      return srData;
    });
    const serviceRequestId = getExternalServiceRequestId(serviceRequestData, client) ?? this.props.srId;

    // get all the communications -  sort them by sent date
    let coms = await client
      .request(
        {
          url: `Communication?based-on=ServiceRequest/${serviceRequestId}&_sort=-sent`,
          federatedServerUrl: this.state.externalServerUrl,
        },
        {
          pageLimit: 0,
          flat: true,
          resolveReferences: ['sender', 'recipient'],
        }
      )
      .then(coms => {
        return coms;
      });

    // get all medias in service request
    let medias = await client
      .request(
        {
          url: `Media?based-on=ServiceRequest/${serviceRequestId}&_summary=true`,
          federatedServerUrl: this.state.externalServerUrl,
        },
        {
          pageLimit: 0,
          flat: true,
        }
      )
      .then(medias => {
        return medias;
      });

    // get necessary data for each communication
    await coms.map(async (com, index) => {
      com.sent = format(parseISO(com.sent), 'P   p', { locale: de });
      com.statusText = t('Draft');
      com.statusBorderColor = colors.statusOrange;
      com.statusColor = colors.statusOrangeOp25;

      // com is not read
      if (com.received == undefined && com.status != 'preparation') {
        com.statusText = t('MessageNotSeen');
        com.statusBorderColor = colors.statusYellow;
        com.statusColor = colors.statusYellowOp25;
      }

      // com is read
      if (com.received != undefined && com.status == 'completed') {
        com.statusText = t('MessageSeen');
        com.statusBorderColor = colors.statusGreen;
        com.statusColor = colors.statusGreenOp25;
      }

      // com is a phone call
      if (
        client.getPath(com, 'topic.coding.0.code') == 'PHONE-CONSULT' &&
        client.getPath(com, 'status') == 'completed'
      ) {
        if (client.getPath(com, 'payload.1.contentString')) {
          const durationPayload = JSON.parse(client.getPath(com, 'payload.1.contentString'));
          if (durationPayload.start_time && durationPayload.end_time) {
            const isValidDate = isValid(
              parseISO(durationPayload.start_time, "yyyy-MM-dd'T'HH:mm:ss.SSSSXXX", new Date())
            );
            let start;
            let end;
            if (!isValidDate) {
              start = new Date((Math.floor(durationPayload.start_time) + 978307200) * 1000); // convert from Apple Core Data Timestamp that counts from 1.1.2001
              end = new Date((Math.floor(durationPayload.end_time) + 978307200) * 1000); // convert from Apple Core Data Timestamp that counts from 1.1.2001
            } else {
              start = new Date(durationPayload.start_time);
              end = new Date(durationPayload.end_time);
            }
            com.duration =
              start && end
                ? formatDistanceStrict(start, end, { locale: enUS.code.includes(this.state.language) ? enUS : de })
                : '-';
          }
        }
        com.statusText = t('Call');
        com.statusBorderColor = colors.statusPurple;
        com.statusColor = colors.statusPurpleOp25;
      }

      // com is a videoconference
      if (client.getPath(com, 'topic.coding.0.code') == 'VIDEOCONFERENCE') {
        if (client.getPath(com, 'payload.1.contentString')) {
          const durationPayload = JSON.parse(client.getPath(com, 'payload.1.contentString'));
          if (durationPayload.start_time && durationPayload.end_time) {
            const start = new Date(durationPayload.start_time);
            const end = new Date(durationPayload.end_time);
            com.duration =
              start && end
                ? formatDistanceStrict(start, end, { locale: enUS.code.includes(this.state.language) ? enUS : de })
                : '-';
          }
        }
        com.statusText = t('Videoconference');
        com.statusBorderColor = colors.statusPurple;
        com.statusColor = colors.statusPurpleOp25;
      }

      // com is a videoconference
      if (client.getPath(com, 'topic.coding.0.code') == 'VIDEOCONFERENCE') {
        if (client.getPath(com, 'payload.1.contentString')) {
          const durationPayload = JSON.parse(client.getPath(com, 'payload.1.contentString'));
          if (durationPayload.start_time && durationPayload.end_time) {
            const start = new Date(durationPayload.start_time);
            const end = new Date(durationPayload.end_time);
            com.duration =
              start && end
                ? formatDistanceStrict(start, end, { locale: enUS.code.includes(this.state.language) ? enUS : de })
                : '-';
          }
        }
        com.statusText = t('Videoconference');
        com.statusBorderColor = colors.statusPurple;
        com.statusColor = colors.statusPurpleOp25;
      }

      // com has a diagnostic report
      // TODO kann partOf mehrere Referenzen enthalten?
      if (
        (com.status == 'not-done' || com.status == 'completed') &&
        client.getPath(com, 'partOf.0.reference') !== undefined &&
        client.getPath(com, 'partOf.0.reference').startsWith('DiagnosticReport')
      ) {
        // read DiagnosticReport
        await client
          .request(
            {
              url: client.getPath(com, 'partOf.0.reference'),
              federatedServerUrl: this.state.externalServerUrl,
            },
            {
              pageLimit: 0,
              flat: true,
            }
          )
          .then(dr_data => {
            // com has unconfirmed diagnostic report
            if (client.getPath(dr_data, 'status') == 'partial') {
              com.statusText = 'ReportUnconfirmed';
              com.statusBorderColor = colors.statusRed;
              com.statusColor = colors.statusRedOp25;
            }
            // com has confirmed diagnostic report
            if (client.getPath(dr_data, 'status') == 'final') {
              com.statusText = 'ReportConfirmed';
              com.statusBorderColor = colors.statusGreen;
              com.statusColor = colors.statusGreenOp25;
            }
          });
      }

      // load images from DocumentReferences
      if (com.payload !== undefined) {
        // save all images in a promise array to resolve once we have them all
        let promises = com.payload.map(async img => {
          if (img.contentReference !== undefined) {
            img = await client
              .request(
                { url: `${img.contentReference.reference}`, federatedServerUrl: this.state.externalServerUrl },
                {
                  pageLimit: 0,
                  flat: true,
                }
              )
              .then(imgData => {
                img.contentReference = imgData.content[0].attachment.data.startsWith('data:')
                  ? imgData.content[0].attachment.data
                  : 'data:' +
                    imgData.content[0].attachment.contentType +
                    ';base64,' +
                    imgData.content[0].attachment.data;
                img.imgTitle = 'image' + com.index + '_' + index;
                index++;
                return img;
              });
            return new Promise(res => {
              res(img);
            });
          }
        });

        // create promise collection to save images from ALL communications and not just the last one
        this.promiseCollection = this.promiseCollection ? this.promiseCollection.concat(promises) : [].concat(promises);
      }

      if (medias !== undefined) {
        let filteredMedia = medias.filter(media => media.partOf[0].reference == 'Communication/' + com.id);
        com.payload = com.payload ? com.payload : [];
        let mappedMedia = await filteredMedia.map(async filterMedia => {
          const media = await client
            .request(
              { url: `Media/${filterMedia.id}`, federatedServerUrl: this.state.externalServerUrl },
              {
                pageLimit: 0,
                flat: true,
              }
            )
            .then(imgData => {
              let img = { contentReference: '', imgTitle: '' };
              img.contentReference = imgData.content.data.startsWith('data:')
                ? imgData.content.data
                : 'data:' + imgData.content.contentType + ';base64,' + imgData.content.data;
              img.imgTitle = 'image' + com.index + '_' + index;
              com.payload = [...com.payload, img];
              index++;
              return img;
            });
          return new Promise(res => {
            res(media);
          });
        });

        this.promiseCollection = this.promiseCollection
          ? this.promiseCollection.concat(mappedMedia)
          : [].concat(mappedMedia);
      }
      com.index++;
    });
    this.setState({ coms: coms });
  };

  onPdfExportClicked = async () => {
    const { t } = this.props;
    await this.loadData();

    const styles = StyleSheet.create({
      body: {
        paddingTop: 35,
        paddingBottom: 65,
        paddingHorizontal: 35,
      },
      text: {
        fontSize: 14,
        textAlign: 'right',
        fontFamily: 'SourceSansPro',
        color: colors.ampDarkBlue,
      },
      footerText: {
        fontSize: 12,
        color: colors.ampDarkestGrey,
        textAlign: 'center',
        bottom: 30,
        position: 'absolute',
      },
    });

    Promise.all(this.promiseCollection).then(async results => {
      const dateNow =
        new Date().toLocaleDateString([]).replaceAll('/', '.') +
        ' - ' +
        new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });

      const MyDoc = (
        <Document>
          <Page size="A4" orientation="portrait" style={styles.body}>
            <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }} fixed>
              <Image src="/ampclinic_logo.png" style={{ width: '150px', marginBottom: 5 }} />
              <View style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', marginTop: 10 }}>
                <Text style={styles.text}>{t('Consultation Export')}</Text>
                <Text style={styles.text}>{dateNow}</Text>
              </View>
            </View>
            <PdfPatientHeaderData
              patientData={this.state.patient}
              caseData={this.state.case}
              t={t}
            ></PdfPatientHeaderData>
            <PdfCommunicationData communicationData={this.state.coms} t={t}></PdfCommunicationData>
            <Text style={[styles.footerText, { left: '6vw' }]} fixed>
              {t('Exported from AMP.clinic version')} {version}
            </Text>
            <Text
              style={[styles.footerText, { right: '7vw' }]}
              render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
              fixed
            />
          </Page>
        </Document>
      );

      const zip = new JSZip();
      const blob = pdf(MyDoc).toBlob();
      const fileName = this.state.patient.name;
      zip.file(fileName + '.pdf', blob);

      results.forEach(image => {
        if (image !== undefined && image.contentReference !== undefined) {
          let img = image.contentReference;
          let imageFileName = image.imgTitle + '.' + img.substring(img.indexOf('/') + 1, img.indexOf(';'));
          zip.file(imageFileName, this.b64toBlob(img.split(',')[1]));
        }
      });

      await zip.generateAsync({ type: 'blob' }).then(function (content) {
        // force download of the zip file
        saveAs(content, fileName ?? 'ConsultationExport');
      });
    });
  };

  b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  };

  componentDidMount() {}

  componentWillUnmount() {}

  render() {
    const { t, i18n } = this.props;

    return (
      <Button
        variant="contained"
        color="primary"
        onClick={this.onPdfExportClicked}
        disabled={!this.props.srIsArchived}
        className="MuiButtonGroup-grouped MuiButtonGroup-groupedHorizontal MuiButtonGroup-groupedOutlined MuiButtonGroup-groupedOutlinedHorizontal MuiButtonGroup-groupedOutlined"
      >
        {t('Export')}
      </Button>
    );
  }
}

export default withTranslation()(PdfExport);

import * as React from 'react';
import { withTranslation } from 'react-i18next';

import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { StepLabel } from '@mui/material';

class DialogStepper extends React.Component {
  constructor(props) {
    super(props);
  }
  componentdidMount() {}

  render() {
    const { t } = this.props;

    return (
      <Box sx={{ width: '100%' }}>
        <Stepper nonLinear activeStep={this.props.activeStep}>
          <Step key={this.props.steps[0]} completed={this.props.completed[0]}>
            <StepLabel error={!Object.values(this.props.errorMandatory).every(value => value == '')}>
              {t(this.props.steps[0])}
            </StepLabel>
          </Step>
          <Step key={this.props.steps[1]} completed={this.props.completed[1]}>
            <StepLabel error={!Object.values(this.props.errorOptional).every(value => value == '')}>
              {t(this.props.steps[1])}
            </StepLabel>
          </Step>
          <Step key={this.props.steps[2]} completed={this.props.completed[2]}>
            <StepLabel>{t(this.props.steps[2])}</StepLabel>
          </Step>
        </Stepper>
        <div>
          <React.Fragment>
            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
              <Button
                color="inherit"
                disabled={this.props.activeStep === 0}
                onClick={this.props.handleBack}
                sx={{ mr: 1 }}
              >
                {t('Back')}
              </Button>
              <Box sx={{ flex: '1 1 auto' }} />
              <Button
                onClick={this.props.isLastStep() ? this.props.onConsultationSubmit : this.props.handleNext}
                disabled={
                  this.props.isLastStep() &&
                  (!Object.values(this.props.errorMandatory).every(value => value == '') ||
                    !Object.values(this.props.errorOptional).every(value => value == ''))
                }
                sx={{ mr: 1 }}
              >
                {this.props.isLastStep() ? t('Create') : t('Next')}
              </Button>
            </Box>
          </React.Fragment>
        </div>
      </Box>
    );
  }
}

export default withTranslation()(DialogStepper);

import React from 'react';
import '../../css/views/CaseDetail.scss';
import { FhirClientContext } from '../../FhirClientContext';
import { withTranslation } from 'react-i18next';
import Communication from './Communication';
import { getExternalServerUrl, getExternalServiceRequestId } from '../utils/checkExternalReference';

/**
 * Wraps everything into `FhirClientProvider` so that any component
 * can have access to the fhir client through the context.
 */
class CommunicationList extends React.Component {
  static contextType = FhirClientContext;

  constructor(props) {
    super(props);
  }

  loadData() {
    const client = this.context.client;

    client
      .request(`ServiceRequest/${this.props.sr_id}`, {
        pageLimit: 0,
        flat: true,
      })
      .then(srData => {
        client
          .request(
            {
              url: `Communication?based-on=ServiceRequest/${
                getExternalServiceRequestId(srData, client) ?? this.props.sr_id
              }&_elements=id&_sort=-sent`,
              federatedServerUrl: getExternalServerUrl(srData, client),
            },
            {
              pageLimit: 0,
              flat: true,
            }
          )
          .then(data => {
            data.forEach(o => {
              this.props.addCommunication(client.getPath(o, 'id'), false);
            });
          });
      });
  }

  componentWillUnmount() {}

  componentDidMount() {
    this.loadData();
  }

  render() {
    return (
      <div
        className={
          'communicationList ' +
          (this.props.isReadOnly ? 'readOnlyHeight' : this.props.showVideoconf ? 'videoConfHeight' : 'normalHeight')
        }
      >
        {this.props.communications.map(item => (
          <Communication
            key={item}
            id={item}
            sr_id={this.props.sr_id}
            srRequester={this.props.srRequester}
            setVideoconferenceLink={this.props.setVideoconferenceLink}
            setVideoconferenceActive={this.props.setVideoconferenceActive}
            onJoinVideoconferenceButtonClick={this.props.onJoinVideoconferenceButtonClick}
            reRender={this.props.reRender}
            addDocRef={this.props.addDocRef}
          />
        ))}
      </div>
    );
  }
}

export default withTranslation()(CommunicationList);

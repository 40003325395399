import React from 'react';
import { withTranslation } from 'react-i18next';
import FhirClientProvider from '../structures/FhirClientProvider';
import Cases from '../elements/Cases';

/**
 * Wraps everything into `FhirClientProvider` so that any component
 * can have access to the fhir client through the context.
 */
class CaseList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showArchive: false,
    };
  }

  componentWillUnmount() {}

  componentDidMount() {}

  render() {
    return (
      <FhirClientProvider>
        <Cases
          key={this.props.match.params.show}
          showArchive={this.props.match.params.show == 'active' ? false : true}
        />
      </FhirClientProvider>
    );
  }
}

export default withTranslation()(CaseList);

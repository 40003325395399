import React from 'react';
import '../../css/views/CaseDetail.scss';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import CommunicationList from '../elements/CommunicationList';
import CaseHeader from '../elements/CaseHeader';
import CaseFooter from '../elements/CaseFooter';
import DicomViewer from '../elements/DicomViewer';
import FhirClientProvider from '../structures/FhirClientProvider';
import Videoconference from '../elements/Videoconference';
import SplitPane from 'react-split-pane';
import ImageCategory from './ImageCategory';

/**
 * Wraps everything into `FhirClientProvider` so that any component
 * can have access to the fhir client through the context.
 */
class CaseDetail extends React.Component {
  constructor(props) {
    super(props);
    const { t } = this.props;
    this.state = {
      srRequester: '',
      srSubject: '',
      srCategory: '',
      srRecipient: '',
      isArchived: false,
      isOnHoldBy: '',
      showDicom: false,
      ownDisplayname: t('Unknown user'),
      videoconf: '',
      videoconfActive: false,
      showVideoconf: false,
      communications: [],
    };
    this.setSrRequester = this.setSrRequester.bind(this);
    this.setSrSubject = this.setSrSubject.bind(this);
    this.setSrIsArchived = this.setSrIsArchived.bind(this);
    this.setSrIsOnHoldBy = this.setSrIsOnHoldBy.bind(this);
    this.setVideoconferenceLink = this.setVideoconferenceLink.bind(this);
    this.setVideoconferenceActive = this.setVideoconferenceActive.bind(this);
    this.setOwnDisplayname = this.setOwnDisplayname.bind(this);
    this.addCommunication = this.addCommunication.bind(this);
    this.reRender = this.reRender.bind(this);
    this.updateCommunications = this.updateCommunications.bind(this);
    this.toggleDicomView = this.toggleDicomView.bind(this);
  }

  componentWillUnmount() {}

  componentDidMount() {}

  setSrRequester = requester => {
    this.setState({ srRequester: requester });
  };

  setSrSubject = subject => {
    this.setState({ srSubject: subject });
  };

  setSrCategory = category => {
    this.setState({ srCategory: category });
  };

  setSrRecipient = recipient => {
    this.setState({ srRecipient: recipient });
  };

  reRender = () => {
    this.forceUpdate();
  };

  setSrIsArchived = archived => {
    this.setState({ isArchived: archived });
  };

  setSrIsOnHoldBy = requester => {
    this.setState({ isOnHoldBy: requester });
  };

  setVideoconferenceLink = link => {
    this.setState({ videoconf: link, videoconfActive: true });
  };

  setVideoconferenceActive = state => {
    this.setState({ videoconfActive: state });
  };

  showVideoconf = show => {
    this.setState({ showVideoconf: show });
  };

  onJoinVideoconferenceButtonClick = () => {
    this.setState({ showVideoconf: true });
  };

  setOwnDisplayname = name => {
    this.setState({ ownDisplayname: name });
  };

  setVideoconferenceLink = link => {
    this.setState({ videoconf: link, videoconfActive: true });
  };

  setVideoconferenceActive = state => {
    this.setState({ videoconfActive: state });
  };

  showVideoconf = show => {
    this.setState({ showVideoconf: show });
  };

  onJoinVideoconferenceButtonClick = () => {
    this.setState({ showVideoconf: true });
  };

  setOwnDisplayname = name => {
    this.setState({ ownDisplayname: name });
  };

  addCommunication = (id, addToBeginning) => {
    if (this.state.communications.includes(id)) {
      return;
    }
    // we get sorted input from the server, so have to add them in the right order
    addToBeginning
      ? this.setState({ communications: [id, ...this.state.communications] })
      : this.setState({ communications: [...this.state.communications, id] });
  };

  updateCommunications = delMe => {
    // this is painful but it's the only way right now
    let comms = this.state.communications;
    if (comms.includes(delMe)) {
      let index = comms.indexOf(delMe);
      comms.splice(index, 1);
      this.setState({ communications: comms });
    }
    this.setState({ communications: [delMe, ...this.state.communications] });
  };

  toggleDicomView = () => {
    this.setState({ showDicom: !this.state.showDicom });
  };

  render() {
    const { t } = this.props;

    let content;
    if (this.state.showDicom || this.props.match.params.dicomid) {
      content = (
        <div>
          <DicomViewer sr_id={this.props.match.params.id} study_id={this.props.match.params.dicomid} />
          <Link to={`..`}>
            <Button variant="contained" color="primary">
              {t('Back')}
            </Button>
          </Link>
        </div>
      );
    } else {
      if (this.state.showVideoconf) {
        content = (
          <SplitPane split="vertical" minSize={500} defaultSize={800} style={{ position: 'relative' }}>
            <div>
              <CommunicationList
                sr_id={this.props.match.params.id}
                srRequester={this.state.srRequester}
                setVideoconferenceLink={this.setVideoconferenceLink}
                setVideoconferenceActive={this.setVideoconferenceActive}
                onJoinVideoconferenceButtonClick={this.onJoinVideoconferenceButtonClick}
                showVideoconf={this.state.showVideoconf}
                reRender={this.reRender}
                addCommunication={this.addCommunication}
                communications={this.state.communications}
                addDocRef={this.addDocRef}
              />
              <CaseFooter
                sr_id={this.props.match.params.id}
                srSubject={this.state.srSubject}
                srCategory={this.state.srCategory}
                srRecipient={this.state.srRecipient}
                reRender={this.reRender}
                srIsOnHoldBy={this.state.isOnHoldBy}
                srIsArchived={this.state.isArchived}
                videoconfActive={this.state.videoconfActive}
                setVideoconferenceLink={this.setVideoconferenceLink}
                setOwnDisplayname={this.setOwnDisplayname}
                addCommunication={this.addCommunication}
                setSrIsArchived={this.setSrIsArchived}
                setSrIsOnHoldBy={this.setSrIsOnHoldBy}
                updateCommunications={this.updateCommunications}
                toggleDicomView={this.toggleDicomView}
              />
            </div>
            <div>
              <Videoconference
                setVideoconferenceActive={this.setVideoconferenceActive}
                showVideoconf={this.showVideoconf}
                roomName={this.state.videoconf}
                userName={this.state.ownDisplayname}
              />
            </div>
          </SplitPane>
        );
      } else {
        content = (
          <div>
            <CommunicationList
              sr_id={this.props.match.params.id}
              srRequester={this.state.srRequester}
              setVideoconferenceLink={this.setVideoconferenceLink}
              setVideoconferenceActive={this.setVideoconferenceActive}
              onJoinVideoconferenceButtonClick={this.onJoinVideoconferenceButtonClick}
              showVideoconf={this.state.showVideoconf}
              reRender={this.reRender}
              addCommunication={this.addCommunication}
              communications={this.state.communications}
              addDocRef={this.addDocRef}
              isReadOnly={this.state.isOnHoldBy || this.state.isArchived}
            />
            <CaseFooter
              sr_id={this.props.match.params.id}
              srSubject={this.state.srSubject}
              srCategory={this.state.srCategory}
              srRecipient={this.state.srRecipient}
              reRender={this.reRender}
              srIsOnHoldBy={this.state.isOnHoldBy}
              srIsArchived={this.state.isArchived}
              videoconfActive={this.state.videoconfActive}
              setVideoconferenceLink={this.setVideoconferenceLink}
              setOwnDisplayname={this.setOwnDisplayname}
              addCommunication={this.addCommunication}
              setSrIsArchived={this.setSrIsArchived}
              setSrIsOnHoldBy={this.setSrIsOnHoldBy}
              updateCommunications={this.updateCommunications}
              toggleDicomView={this.toggleDicomView}
            />
          </div>
        );
      }
    }

    return (
      <FhirClientProvider>
        <div className="caseDetailBox">
          <div className="caseDetail mainView">
            <CaseHeader
              sr_id={this.props.match.params.id}
              setSrRequester={this.setSrRequester}
              setSrSubject={this.setSrSubject}
              setSrCategory={this.setSrCategory}
              setSrRecipient={this.setSrRecipient}
              setSrIsArchived={this.setSrIsArchived}
              setSrIsOnHoldBy={this.setSrIsOnHoldBy}
              srIsOnHoldBy={this.state.isOnHoldBy}
              srIsArchived={this.state.isArchived}
            />
            {content}
          </div>
          <ImageCategory srId={this.props.match.params.id} />
        </div>
      </FhirClientProvider>
    );
  }
}

export default withTranslation()(CaseDetail);

export function isExternalReference(ref: string, client: any) {
  console.log(ref);
  const clientServerUrl = client.state.serverUrl.endsWith('/') ? client.state.serverUrl : client.state.serverUrl + '/';

  try {
    const url = new URL(ref);
    if (url.origin + '/hapi-fhir-jpaserver/fhir/' != clientServerUrl) return true;
  } catch (e) {
    return false;
  }
  return false;
}

export function getExternalServiceRequestId(serviceRequest: any, client: any) {
  if (serviceRequest.basedOn != undefined && isExternalReference(serviceRequest.basedOn[0].reference, client)) {
    const url = new URL(serviceRequest.basedOn[0].reference);
    return url.pathname.replaceAll('/hapi-fhir-jpaserver/fhir/', '').split('/')[1];
  } else return null;
}

export function getExternalServerUrl(serviceRequest: any, client: any) {
  if (serviceRequest.basedOn != undefined && isExternalReference(serviceRequest.basedOn[0].reference, client)) {
    const url = new URL(serviceRequest.basedOn[0].reference);
    return url.origin + '/hapi-fhir-jpaserver/fhir/';
  } else return null;
}

import React from 'react';
import FhirClientProvider from '../structures/FhirClientProvider';
import { withTranslation } from 'react-i18next';
import NewCaseFormular from '../elements/NewCaseFormular';

/**
 * Wraps everything into `FhirClientProvider` so that any component
 * can have access to the fhir client through the context.
 */
class NewCase extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <FhirClientProvider>
        <NewCaseFormular />
      </FhirClientProvider>
    );
  }
}

export default withTranslation()(NewCase);

import React from 'react';
import { oauth2 as SMART } from 'fhirclient';
import { FhirClientContext } from '../../FhirClientContext';
import { withTranslation } from 'react-i18next';
import history from '../../history';

class FhirClientProvider extends React.Component {
  componentDidMount() {
    SMART.ready().then(
      client => this.setState({ client }),
      error => this.setState({ error })
    );
  }

  render() {
    const { t } = this.props;
    return (
      <FhirClientContext.Provider value={this.state || {}}>
        <FhirClientContext.Consumer>
          {({ client, error }) => {
            // any error that SMART.ready() may have been rejected with
            if (error) {
              // print error to console.log();
              console.log(error.stack);
              // return to launcher for login
              history.push(`/`);
            }

            // if client is already available render the subtree
            if (client) {
              return this.props.children;
            }

            // client is undefined until SMART.ready() is fulfilled
            return t('Authorizing...');
          }}
        </FhirClientContext.Consumer>
      </FhirClientContext.Provider>
    );
  }
}

export default withTranslation()(FhirClientProvider);

import React from 'react';
import { FhirClientContext } from '../../FhirClientContext';
import { withTranslation } from 'react-i18next';
import { installViewer } from '@ohif/viewer';
import { getVectorConfig } from '../utils/getConfig';

class DicomViewer extends React.Component {
  static contextType = FhirClientContext;
  containerId = 'ohif';

  constructor(props) {
    super(props);

    this.state = {
      isAudioMuted: false,
      isVideoMuted: false,
    };
  }

  async componentDidMount() {
    const config = await getVectorConfig('/config');

    const ohifViewerConfig = {
      routerBasename: '/casedetail/' + this.props.sr_id + '/',
      showStudyList: false,
      servers: {
        dicomWeb: [
          {
            name: 'DCM4CHEE',
            wadoUriRoot: config.dicom_wado_uri,
            qidoRoot: config.dicom_qido_uri,
            wadoRoot: config.dicom_wado_rs_uri,
            qidoSupportsIncludeField: true,
            imageRendering: 'wadors',
            thumbnailRendering: 'wadors',
          },
        ],
      },
    };
    installViewer(ohifViewerConfig, this.containerId, this.componentRenderedOrUpdatedCallback);
  }

  componentRenderedOrUpdatedCallback = () => {
    console.log('OHIF Viewer rendered/updated');
  };

  render() {
    return <div id={this.containerId} className="normalHeight" />;
  }
}
export default withTranslation()(DicomViewer);

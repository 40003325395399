import { Fragment } from 'react';
import { Image, View, Text, StyleSheet } from '@react-pdf/renderer';
import colors from './../../../css/theme.module.scss';

const styles = StyleSheet.create({
  text: {
    paddingLeft: 5,
    paddingRight: 5,
    paddingBottom: 5,
    fontFamily: 'SourceSansPro',
    color: colors.ampDarkBlue,
    fontSize: 10,
  },
  image: {
    paddingLeft: 5,
    paddingRight: 5,
    paddingTop: 5,
    width: '100px',
    height: '100px',
  },
});

const PdfCommunicationImage = ({ imgData }) => {
  if (!imgData) return null;
  const filteredData = imgData.filter(o => o.contentReference !== undefined);
  const comImg = (
    <View style={{ display: 'flex', flexDirection: 'row', marginBottom: 10 }}>
      {filteredData.map((img, index) => (
        <View key={index}>
          <Image src={img.contentReference} style={styles.image} key={(index += 1)} />
          <Text style={styles.text}>{img.imgTitle}</Text>
        </View>
      ))}
    </View>
  );
  return <Fragment>{comImg}</Fragment>;
};

export default PdfCommunicationImage;

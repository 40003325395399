import { Fragment } from 'react';
import { Text, View, StyleSheet, Font } from '@react-pdf/renderer';
import colors from './../../../css/theme.module.scss';
import font from './../../../assets/fonts/SourceSansPro-Regular.ttf';

const styles = StyleSheet.create({
  text: {
    marginTop: 0,
    marginBottom: 5,
    fontSize: 14,
    textAlign: 'center',
    fontFamily: 'SourceSansPro',
    color: colors.ampDarkBlue,
  },
  patient: {
    fontsize: 18,
    textAlign: 'center',
    fontFamily: 'SourceSansPro',
    color: colors.ampDarkBlue,
  },
  infoText: {
    marginTop: 5,
    marginBottom: 0,
    fontSize: 12,
    textAlign: 'center',
    fontFamily: 'SourceSansPro',
    color: colors.ampDarkestGrey,
  },
  headerBox: {
    backgroundColor: colors.ampBackground,
    borderRadius: '5px',
    justifyContent: 'center',
  },
});

const PdfPatientHeaderData = ({ patientData, caseData, t }) => {
  Font.register({ family: 'SourceSansPro', src: font });
  const header = (
    <Fragment>
      <View style={{ flexDirection: 'row' }}>
        <View style={{ flex: 1 }}>
          <View style={styles.headerBox}>
            <Text style={styles.infoText}>{t('Counterpart')}</Text>
            <Text style={styles.text}> {caseData.partner}</Text>
          </View>
          <View style={[styles.headerBox, { borderTop: '3px solid white', borderBottom: '3px solid white' }]}>
            <Text style={styles.infoText}>{t('Consultation Participant')}</Text>
            <Text style={styles.text}>{caseData.consultParticipant}</Text>
          </View>
          <View style={styles.headerBox}>
            <Text style={styles.infoText}>{t('Insurance')}</Text>
            <Text style={styles.text}>{caseData.payer}</Text>
          </View>
        </View>
        <View
          style={{ flex: 1, borderLeft: '3px solid white', borderRight: '3px solid white', flexDirection: 'column' }}
        >
          <View style={[styles.headerBox, { flex: 1, justifyContent: 'center' }]}>
            <Text style={styles.patient}>{patientData.name}</Text>
          </View>
          <View
            style={{ flexDirection: 'row', flex: 1, borderTop: '3px solid white', borderBottom: '3px solide white' }}
          >
            <View style={[styles.headerBox, { flex: 1, borderRight: '3px solid white' }]}>
              <Text style={styles.infoText}>{t('Birthdate')}</Text>
              <Text style={styles.text}>{patientData.birthdate}</Text>
            </View>
            <View style={[styles.headerBox, { flex: 1 }]}>
              <Text style={styles.infoText}>{t('Gender')}</Text>
              <Text style={styles.text}>{patientData.gender}</Text>
            </View>
          </View>
          <View style={{ flexDirection: 'row', flex: 1 }}>
            <View style={[styles.headerBox, { flex: 1, borderRight: '3px solid white' }]}>
              <Text style={styles.infoText}>{t('Height')}</Text>
              <Text style={styles.text}>{patientData.height}</Text>
            </View>
            <View style={[styles.headerBox, { flex: 1 }]}>
              <Text style={styles.infoText}>{t('Weight')}</Text>
              <Text style={styles.text}>{patientData.weight}</Text>
            </View>
          </View>
        </View>
        <View style={{ flex: 1, flexDirection: 'column' }}>
          <View style={[styles.headerBox, { borderBottom: '3px solid white', flex: 1 }]}>
            <Text style={styles.infoText}>{t('Contact person')}</Text>
            <Text
              style={{
                marginTop: 0,
                fontSize: 14,
                textAlign: 'center',
                fontFamily: 'SourceSansPro',
                color: colors.ampDarkBlue,
              }}
            >
              {caseData.counterpart}
            </Text>
            <Text
              style={{
                marginTop: 0,
                marginBottom: 5,
                fontSize: 14,
                textAlign: 'center',
                fontFamily: 'SourceSansPro',
                color: colors.ampDarkBlue,
              }}
            >
              {caseData.requester}
            </Text>
          </View>
          <View style={[styles.headerBox, { flex: 1, justifyContent: 'center' }]}>
            <Text style={styles.infoText}>{t('Callback Number')}</Text>
            <Text style={styles.text}>{caseData.callbacknumber}</Text>
          </View>
        </View>
      </View>
    </Fragment>
  );
  return header;
};

export default PdfPatientHeaderData;

import React from 'react';
import { withTranslation } from 'react-i18next';
import { FhirClientContext } from '../../FhirClientContext';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import LinearProgress from '@mui/material/LinearProgress';
import { getVectorConfig } from '../utils/getConfig';

class DicomUploadDialog extends React.Component {
  static contextType = FhirClientContext;

  constructor(props) {
    super(props);

    this.state = {
      fileList: [],
      dicomError: '',
      percentComplete: {},
      baseUrl: '',
      dicomUploadAllowed: false,
      studyId: '',
    };

    this.fileChange = this.fileChange.bind(this);
  }

  async componentDidMount() {
    const config = await getVectorConfig('/config');
    this.setState({ baseUrl: config.dicom_wado_rs_uri });
  }

  componentWillUnmount() {}

  onClose = event => {
    // save communication with study
    console.log(this.state.studyId);
    if (this.state.studyId) {
      console.log('Creating Communication for ' + this.state.studyId);
      this.props.saveDicom(this.state.studyId);
    }
    this.setState({ dicomError: '', fileList: [], dicomUploadAllowed: false, studyId: '' });

    this.props.onClose();
  };

  fileChange = event => {
    // check for correct filetype
    const { t } = this.props;
    let error = '';
    Array.from(event.target.files).forEach(file => {
      if (file.type && file.type != 'application/dicom') {
        error = t('Invalid file type');
      }
    });

    if (error) {
      this.setState({ dicomError: error, fileList: [], dicomUploadAllowed: false });
      return;
    }
    // add files to filelist if they are valid
    this.setState({ fileList: event.target.files, dicomError: '', dicomUploadAllowed: true });
  };

  onUploadDicom = () => {
    const { t } = this.props;
    let boundary = Math.random().toString().substr(2);
    let filetype;
    // get token
    const client = this.context.client;
    const token = client.state.tokenResponse.access_token;
    // get study base url
    const url = this.state.baseUrl + '/studies';

    if (!url) {
      console.error('No Study URL defined!');
      return;
    }

    // block upload button to avoid duplicate uploads
    this.setState({ dicomUploadAllowed: false });

    if (this.state.fileList) {
      Array.from(this.state.fileList).forEach(file => {
        if (file.type && file.type != 'application/dicom') {
          const error = t('Invalid file type');
          this.setState({ dicomError: error, fileList: [] });
        } else {
          console.log('file', file);
          console.log('filetype', file.type);

          this.setState({
            percentComplete: {
              ...this.state.percentComplete,
              [file.name]: { showLoader: true, value: 0, showTicker: false },
            },
          });

          console.log(this.state);

          let xmlHttpRequest = new XMLHttpRequest();

          xmlHttpRequest.open('POST', url, true);
          let dashes = '--';
          let crlf = '\r\n';
          //Post with the correct MIME type (If the OS can identify one)
          if (file.type == '') {
            filetype = 'application/dicom';
          } else {
            filetype = file.type;
          }
          const postDataStart =
            dashes +
            boundary +
            crlf +
            'Content-Disposition: form-data;' +
            'name="file";' +
            'filename="' +
            encodeURIComponent(file.name) +
            '"' +
            crlf +
            'Content-Type: ' +
            filetype +
            crlf +
            crlf;
          const postDataEnd = crlf + dashes + boundary + dashes;
          xmlHttpRequest.setRequestHeader(
            'Content-Type',
            'multipart/related;type="application/dicom";boundary=' + boundary
          );
          xmlHttpRequest.setRequestHeader('Accept', 'application/dicom+json');
          xmlHttpRequest.setRequestHeader('Authorization', `Bearer ${token}`);

          xmlHttpRequest.upload.onprogress = e => {
            if (e.lengthComputable) {
              console.log(this.state);
              this.setState({
                percentComplete: {
                  ...this.state.percentComplete,
                  [file.name]: {
                    ...this.state.percentComplete[file.name],
                    value: (e.loaded / e.total) * 100,
                  },
                },
              });
            }
          };
          xmlHttpRequest.onreadystatechange = () => {
            if (xmlHttpRequest.readyState === 4) {
              if (xmlHttpRequest.status === 200) {
                const response = JSON.parse(xmlHttpRequest.response);
                console.log('in response', response);

                // extract study id from completed upload
                let studyId = '';
                Object.keys(response).forEach(element => {
                  console.log(response[element]);

                  if (response[element]['vr'] && response[element]['vr'] == 'UR') {
                    studyId = response[element]['Value'][0].split('/').slice(-1);
                  }
                });

                this.setState({ studyId: studyId });
                console.log('Study ID: ' + studyId);

                this.setState({
                  percentComplete: {
                    ...this.state.percentComplete,
                    [file.name]: {
                      ...this.state.percentComplete[file.name],
                      showLoader: false,
                      showTicker: true,
                    },
                  },
                });
              } else {
                console.log('in response error', xmlHttpRequest.status);
                console.log('statusText', xmlHttpRequest.statusText);
                this.setState({
                  percentComplete: {
                    ...this.state.percentComplete,
                    [file.name]: {
                      ...this.state.percentComplete[file.name],
                      showLoader: false,
                      value: 0,
                      status: xmlHttpRequest.status + ' ' + xmlHttpRequest.statusText,
                    },
                  },
                });
                console.log(xmlHttpRequest.response);
                //let jsonFormat = JSON.parse(xmlHttpRequest.response);
                //this.httpErrorHandler.handleError(jsonFormat || xmlHttpRequest.response);
              }
            }
          };
          xmlHttpRequest.upload.onloadstart = e => {
            this.setState({
              percentComplete: {
                ...this.state.percentComplete,
                [file.name]: {
                  ...this.state.percentComplete[file.name],
                  value: 0,
                },
              },
            });
          };
          xmlHttpRequest.upload.onloadend = e => {
            if (xmlHttpRequest.status === 200) {
              this.setState({
                percentComplete: {
                  ...percentComplete,
                  [file.name]: { showLoader: false, value: 100 },
                },
              });
            }
          };
          //Send the binary data
          xmlHttpRequest.send(new Blob([new Blob([postDataStart]), file, new Blob([postDataEnd])]));
        }
      });
    }
  };

  render() {
    const { t } = this.props;
    let uploadState = [];

    Array.from(this.state.fileList).forEach(file => {
      console.log(file);
      console.log(file.name);
      let fileState = (
        <div className="upload_state" key={file.name}>
          {file.name}
          {this.state.percentComplete[file.name] && this.state.percentComplete[file.name].status && (
            <span className="files error">Error {this.state.percentComplete[file.name].status}</span>
          )}
          {this.state.percentComplete[file.name] &&
            this.state.percentComplete[file.name].showLoader &&
            !this.state.percentComplete[file.name].showTicker && (
              <span className="upload_ticker fa-circle-o-notch fa-spin">
                <FontAwesomeIcon icon={faCircleNotch} />
              </span>
            )}
          {this.state.percentComplete[file.name] && this.state.percentComplete[file.name].showTicker && (
            <span className="upload_ticker">
              <FontAwesomeIcon icon={faCheck} />
            </span>
          )}
          {this.state.percentComplete &&
            this.state.percentComplete[file.name] &&
            this.state.percentComplete[file.name].value && (
              <div>
                <LinearProgress variant="determinate" value={this.state.percentComplete[file.name].value} />
              </div>
            )}
        </div>
      );
      uploadState.push(fileState);
    });

    return (
      <Dialog open={this.props.open} onClose={this.onClose} className="form">
        <div>
          <DialogTitle>{t('DICOM Images')}</DialogTitle>
          <DialogContent>
            <DialogContentText>{t('Upload your DICOM file(s):')}</DialogContentText>
            <span className="files text">{t('or drag it here.')}</span>
            <div className="files input">
              <input type="file" multiple onChange={this.fileChange} />
            </div>
            <img src="/upload.png" className="files image" />
            <div className="files error">{this.state.dicomError}</div>
            {uploadState}
          </DialogContent>
          <DialogActions>
            <Button onClick={this.onClose} color="primary">
              {t('Cancel')}
            </Button>
            <Button onClick={this.onUploadDicom} color="primary" disabled={!this.state.dicomUploadAllowed}>
              {t('Upload')}
            </Button>
          </DialogActions>
        </div>
      </Dialog>
    );
  }
}

export default withTranslation()(DicomUploadDialog);

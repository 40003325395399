import React from 'react';
import { oauth2 as SMART } from 'fhirclient';
import { withTranslation } from 'react-i18next';
import { getVectorConfig } from '../utils/getConfig';

/**
 * Typically the launch page is an empty page with a `SMART.authorize`
 * call in it.
 */
class Launcher extends React.Component {
  /**
   * This is configured to make a Standalone Launch, just in case it
   * is loaded directly. An EHR can still launch it by passing `iss`
   * and `launch` url parameters
   */
  async componentDidMount() {
    const config = await getVectorConfig('/config');
    SMART.authorize({
      clientId: 'hapifhir-client',
      scope: 'offline_access openid',
      redirectUri: '/app',
      iss: config.iss,
    });
  }
  /**
   * Could also return `null` for empty page
   */
  render() {
    const { t } = this.props;
    return <div className="amp">{t('Launching...')}</div>;
  }
}

export default withTranslation()(Launcher);
